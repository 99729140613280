import React, { useState } from 'react';

import Sidebar from '../components/Sidebar';
import { changePassword } from '../config/parametreConfig';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Parametres = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmedNewPassword, setConfirmedNewPassword] = useState('');
    const [error1, setError1] = useState('');
    const [error2, setError2] = useState('');

    const notifySuccess = () => toast.success('Le mot de passe a été changé avec succès', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const handleChangePassword = async () => {
        if (newPassword.length < 6) {
            setError1('Le mot de passe doit contenir au moins 6 caractères');
            setError2('');
        } else if (newPassword.length >= 6 && newPassword !== confirmedNewPassword) {
            setError1('');
            setError2('les mot de passe ne son pas les mêmes !!');
        } else if (newPassword === confirmedNewPassword) {
            setError1('');
            setError2('');
            changePassword(newPassword);

            setNewPassword(''); // Reset Fields
            setConfirmedNewPassword(''); // Reset Fields

            notifySuccess(); // Show the toast
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleChangePassword();
        }
    };

    return (
        <div className="flex lg:gap-5 bg-[#fffcf6]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                />

                <p className='text-4xl lg:text-5xl font-bold mb-16'>Paramètres</p>

                <div className='flex items-center justify-center mt-20'>
                    <div className='w-full lg:w-[60%] bg-white shadow-lg rounded-3xl p-5 lg:p-10'>
                        <p className='text-center text-2xl lg:text-3xl font-bold mb-10'>Changer le mot de passe</p>

                        {
                            error1.length > 0 && (
                                <div className='mb-6 bg-[#F45050] rounded-md'>
                                    <p className='text-white font-semibold text-center py-2'>{error1}</p>
                                </div>
                            )
                        }

                        {
                            error2.length > 0 && (
                                <div className='mb-6 bg-[#F45050] rounded-md'>
                                    <p className='text-white font-semibold text-center py-2'>{error2}</p>
                                </div>
                            )
                        }

                        <div className='mb-6'>
                            <p>Nouveau mot de passe</p>
                            <input
                                type="text"
                                className='w-full border border-gray outline-none px-2 py-1 rounded-md'
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        <div className='mb-10'>
                            <p>Confirmer le mot de passe</p>
                            <input
                                type="text"
                                className='w-full border border-gray outline-none px-2 py-1 rounded-md'
                                value={confirmedNewPassword}
                                onChange={(e) => setConfirmedNewPassword(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        <div className='flex justify-end'>
                            <button
                                className='bg-primary text-white font-semibold px-10 py-2 rounded-md'
                                onClick={handleChangePassword}
                            >
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}

export default Parametres;