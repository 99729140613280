// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getDatabase, ref } from "firebase/database";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAq-3XSl1JQPWpZw8a3AAsFsn8TkxMCuVw",
    authDomain: "pdever-elbahja.firebaseapp.com",
    databaseURL: "https://pdever-elbahja-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "pdever-elbahja",
    storageBucket: "pdever-elbahja.appspot.com",
    messagingSenderId: "946373995853",
    appId: "1:946373995853:web:c3a8b36ea9684590945b04",
    measurementId: "G-B3T3B2PY57"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const realtimeDb = getDatabase(app);
const auth = getAuth();

const dataCollection = collection(db, "Data");
const logsCollection = collection(db, "Logs");
const porteLogsCollection = collection(db, "PorteLogs");

const chambersRef = ref(realtimeDb, 'chambers');
const accessRef = ref(realtimeDb, 'access');

export {
    dataCollection,
    logsCollection,
    porteLogsCollection,
    chambersRef,
    auth,
    accessRef
};