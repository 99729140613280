import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

import Logo from '../assets/images/PDever.svg';
import Load from '../assets/images/loading-primary-bg.gif';

import { loginFunctionAndUpdateStatus } from '../config/loginConfig';

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const check = await loginFunctionAndUpdateStatus(email, password);

            if (check === 'out') {
                setError('E-mail ou Mot de passe est incorrect !');
            } else if (check === 'online') {
                setError('Cet utilisateur est connecté sur un autre appareil !');
            } else if (check === 'in') {
                localStorage.setItem('isAuth', true);
                // console.log('isAuth :', localStorage.getItem('isAuth'));
                return navigate('/chambres');
            } else if (check === 'access') {
                setError('Vous n\'avez pas accès pour vous connecter !');
            }

        } catch (error) {
            setError('Une erreur s\'est produite lors de la connexion');
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleLogin();
            setIsLoading(true);
        }
    };

    return (
        <div>
            <div className='flex max-h-screen overflow-hidden'>
                <div className='w-1/2 bg-image bg-gray-50 h-screen hidden lg:block'></div>

                <div className='w-full lg:w-1/2 flex flex-col items-center justify-center px-10 lg:px-0'>
                    <img src={Logo} alt="" className='mt-20 md:mt-0' />
                    <div className='w-full lg:w-[50%] mx-auto mt-20'>
                        <div>
                            <p className='font-semibold'>E-mail</p>
                            <input
                                type="text"
                                className='w-full border border-gray rounded-md outline-none px-2 py-1'
                                placeholder='eg: email@gmail.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={handleKeyPress}
                                autoComplete="true"
                            />
                        </div>

                        <div className='mt-5'>
                            <p className='font-semibold'>Mot de passe</p>
                            <input
                                type="password"
                                className='w-full border border-gray rounded-md outline-none px-2 py-1'
                                placeholder='************'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        <div className='w-full mt-5'>
                            <p className='text-center text-red font-semibold'>{error}</p>
                        </div>
                        
                        <div className='mt-8 w-full flex justify-end'>
                            <button
                                className='font-semibold bg-primary px-10 py-1 text-white rounded-md outline-none'
                                onClick={() => handleLogin()}
                            >
                                {
                                    isLoading ? (
                                        <img src={Load} alt='' width={30} />
                                    ) : (
                                        <p>Se connecter</p>
                                    )
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;